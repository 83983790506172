.App {
  text-align: center;
  background-color: #f8f1ee;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 2px 0px 0px 0px black, -2px 0px 0px 0px black,
    0px 2px 0px 0px black, 0px -2px 0px 0px black, 1px 1px 0px 0px black,
    -1px -1px 0px 0px black, 3px 1px 0px 0px black, -3px -1px 0px 0px black;
  /* min-height: 80vh; */
}